import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";
import * as s from "./styles/globalStyles";
import styled from "styled-components";
import i1 from "./assets/images/mainimage.png";

import i2 from "./assets/images/1-7t.png";
import i3 from "./assets/images/2-7t.png";
import i4 from "./assets/images/3-7t.png";
import i5 from "./assets/images/4-7t.png";
import i6 from "./assets/images/5-7t.png";
import i7 from "./assets/images/6-7t.png";
import i8 from "./assets/images/7-7t.png";
const Photo1 = styled.img`
  object-fit: contain;
`;
const Photo2 = styled.img`
  object-fit: contain;
`;
const Photo3 = styled.img`
  object-fit: contain;
`;
const Photo4 = styled.img`
  object-fit: contain;
`;
const Photo5 = styled.img`
  object-fit: contain;
`;
const Photo6 = styled.img`
  object-fit: contain;
`;
const Photo7 = styled.img`
  object-fit: contain;
`;



export const StyledButton = styled.button`
  padding: 10px;
  border-radius: 10px;
  border: none;
  background-color: #ffffff;
  font-weight: bold;
  font-size: 20px;
  color: #000000;
  width: 200px;
  height: 50px;
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;


export const ResponsiveWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: stretched;
  align-items: stretched;
  width: 75%;
  @media (min-width: 767px) {
    flex-direction: row;
  }
`;

//The Logo
export const StyledImg = styled.img`   
  width: 400px;
  height: 200px;
  @media (min-width: 767px) {
    width: 700px;
    height: 467px;
  }
  transition: width 0.5s;
  transition: height 0.5s;
`;

function App() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [feedback, setFeedback] = useState("-----------------");
  const [claimingNft, setClaimingNft] = useState(false);

  const claimNFTs = (_amount) => {
    if (_amount <= 0) {
      return;
    }
    setFeedback("Minting your CryptoCab. This could take several minutes......");
    setClaimingNft(true);
    blockchain.smartContract.methods
      .mint(blockchain.account, _amount)
      .send({
        gasLimit: "1000000",
        to: "0x7b1B6d51194a58018547180573995102580b37C8",  //change***
        from: blockchain.account,
        value: blockchain.web3.utils.toWei((0.1 * _amount).toString(), "ether"),   //change*** Price 10 matic? 
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Something went wrong, or you canceled the transaction.  Please try again.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        setFeedback(
          //window.open('https://www.codexworld.com', '_blank');
          "Minting Complete! Visit https://opensea.io/assets/cryptocabz-s1 to view, trade or sell it!"
        );
        setClaimingNft(false);
        dispatch(fetchData(blockchain.account));
      });
  };

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  };

  useEffect(() => {
    getData();
  }, [blockchain.account]);

  return (

    <s.Screen style={{ backgroundColor: "var(--black)" }}>
      <s.Container flex={1} ai={"center"} style={{ padding: 24 }}>
        <s.TextTitle
          style={{ textAlign: "center", fontSize: 36, fontWeight: "bold", padding:16 }}
        >
          Welcome to CryptoCabz!
        </s.TextTitle>
      <s.Container
          ai={"center"}
          jc={"center"}
          fd={"row"}
          style={{
            
            overflow: "hidden",
            
          }}
        ><Photo1 src={i2} />
        <Photo2 src={i3} />
        <Photo3 src={i4} />
        <Photo4 src={i5} />
        <Photo5 src={i6} />
        <Photo6 src={i7} />
        <Photo7 src={i8} />

        </s.Container>
        <s.SpacerMedium />
        <ResponsiveWrapper flex={1} style={{ padding: 24 }}>
          {/* <s.Container flex={1} jc={"center"} ai={"center"}>
            <StyledImg alt={"example"} src={i1} />
            
      
          </s.Container> */}
          <s.SpacerMedium />
          <s.Container
            flex={1}
            //jc={"center"}
            ai={"center"}
            style={{ backgroundColor: "var(--black)", padding: 5}}
          >
            {Number(data.totalSupply) == 10000 ? (
              <>
              
                <s.TextTitle style={{ textAlign: "center" }}>
                  CryptoCabz is Sold Out!
                  
                </s.TextTitle>
                <s.SpacerSmall />
                <s.TextDescription style={{ textAlign: "center" }}>
                  You can still find CryptoCabz on{" "}
                  <a
                    target={"_blank"}
                    href={"https://opensea.io/assets/cryptocabz-s1"}  //change***
                  >
                    Opensea.io
                  </a>
                </s.TextDescription>
              </>
            ) : (
              <>
              <StyledImg alt={"example"} src={i1} />
                <s.TextTitle style={{ textAlign: "center" }}>
                  CryptoCabz are 0.1 MATIC.
                </s.TextTitle>
                <s.SpacerXSmall />
                <s.TextDescription style={{ textAlign: "center" }}>
                  Excluding gas fee.
                  <s.SpacerMedium />
                </s.TextDescription>
                <s.SpacerSmall />
                <s.TextDescription style={{ textAlign: "center" }}>
                  {feedback}
                </s.TextDescription>
                <s.SpacerMedium />
                {blockchain.account === "" ||
                blockchain.smartContract === null ? (
                  <s.Container ai={"center"} jc={"center"}>
                    <s.TextDescription style={{ textAlign: "center" }}>
                      Please connect to the Polygon Network in Metamask
                    </s.TextDescription>
                    <s.SpacerSmall />
                    <StyledButton
                      onClick={(e) => {
                        e.preventDefault();
                        dispatch(connect());
                        getData();
                      }}
                    >
                      CONNECT
                    </StyledButton>
                    {blockchain.errorMsg !== "" ? (
                      <>
                        <s.SpacerSmall />
                        <s.TextDescription style={{ textAlign: "center" }}>
                          {blockchain.errorMsg}
                        </s.TextDescription>
                      </>
                    ) : null}
                  </s.Container>
                ) : (
                  <s.Container ai={"center"} jc={"center"} fd={"row"} >
                    <StyledButton
                      disabled={claimingNft ? 1 : 0}
                      onClick={(e) => {
                        e.preventDefault();
                        claimNFTs(1);   // 1 indicates how many to mint.
                        getData();
                      }}
                    >
                      {claimingNft ? "BUSY" : "Buy 1 CryptoCab"}
                    </StyledButton>
                  </s.Container>
                )}
                <s.TextTitle
              style={{ textAlign: "center", fontSize: 18, fontWeight: "bold", padding:20 }}
            >
              {data.totalSupply} / 10000 Cabz Minted
            </s.TextTitle>

              </>
            )}
          </s.Container>
        </ResponsiveWrapper>
        <s.SpacerSmall />
        <s.Container jc={"center"} ai={"center"} style={{ width: "70%" }}>
          <s.TextDescription style={{ textAlign: "left", fontSize: 12 }}>
            Please make sure you are connected to the right network (Polygon
            Mainnet) and the correct address. Please note: Once you make the
            purchase, you cannot undo this action.  If you need help getting setup
            on the Polygon Network, please follow these instructions {" "}
            <a
                    target={"_blank"}
                    href={"https://docs.matic.network/docs/develop/metamask/config-polygon-on-metamask/"}
                  >
                    Configure Polygon on Metamask
                  </a>
          </s.TextDescription>
          <s.SpacerSmall />
          <s.TextDescription style={{ textAlign: "left", fontSize: 12 }}>
            We have set the gas limit to 300000 for the contract to successfully
            mint your NFT. We recommend that you don't change the gas limit.
          </s.TextDescription>
          <s.SpacerSmall />
          <s.TextDescription style={{ textAlign: "left", fontSize: 12 }}>
            If you have any questions please contact us through {" "} 
            <a
                    target={"_blank"}
                    href={"https://www.arcaded.io"}
                  >
                    arcaded.io
                  </a>
          </s.TextDescription>
        </s.Container>
      </s.Container>
    </s.Screen>
  );
}

export default App;
